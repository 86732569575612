import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import './banner.css';
import { Link } from 'react-scroll';

const Banner = ({ isNotHomePage }) => {

  return (
    <div>
      <OwlCarousel className="owl-theme banner-slider" dots items={1} margin={0}>
        {!isNotHomePage && (
          <div className="item">
            <img src="banner/launch.png" className='position-right' alt="Banner" title="New vehicle" />
            <div className='launch-banner-caption'>
              <h1>
                🎉 Unlocked <span>New Horizons</span>🎉
                <br />
                Our <span>Latest Vehicle</span> Took the <span>Lead!</span>
              </h1>
              <Link
                to="contactPage"
                spy
                offset={-120}
                smooth
                duration={500}
                containerId="scrollElement"
                href="#"
                className='btn btn-primary'
              >
                Know more
              </Link>
            </div>
          </div>
        )}
        <div className="item">
          <img src="banner/1.png" alt="Banner" title="AKX" />
        </div>
        <div className="item">
          <div className='banner-carrier-caption'>
            <h4>
              AKX provides multiple utility value in its segment
            </h4>
          </div>
          <img src="banner/2.png" alt="Banner" title="AKX" />
        </div>
      </OwlCarousel>
    </div>
  )
}

export default Banner
