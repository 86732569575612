import React from 'react'
import { Element } from 'react-scroll';
import Banner from '../components/Banner';
import About from '../components/About';
import Product from '../components/Product';
import Video from '../components/Video';
import Slider from '../components/Slider';
import TwoBikes from '../components/TwoBikes';
import WhoWeAre from '../components/WhoWeAre';
import Contact from '../components/Contact';
import Footer from '../shared/Footer';
import Layout from './Layout';
import ApplicationHelmet from '../components/Helmet';
import PressRelease from '../components/PressRelease';

const AkxProduct = () => {
    return (
        <ApplicationHelmet>
            <Layout isNotHomePage={true}>
                <Element name="homePage">
                    <Banner isNotHomePage={true} />
                </Element>
                <div id="aboutPage">
                    <About />
                    <WhoWeAre />
                </div>
                <div id="productPage">
                    <Product />
                </div>
                <Video />
                <div id="featurePage">
                    <Slider />
                </div>
                <div id="colorsPage">
                    <TwoBikes />
                </div>
                <div id="pressRelease">
                    <PressRelease />
                </div>
                <div id="contactPage">
                    <Contact />
                </div>
                <Footer />
            </Layout>
        </ApplicationHelmet>
    )
}

export default AkxProduct;